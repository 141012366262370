import React from 'react';
import {FaFileAlt, FaFilePdf, FaFileWord} from 'react-icons/fa';
import {AttachmentResponse} from "../../types/types";

interface FileInfoProps {
    fileInfo: AttachmentResponse | null;
    fileBlob: Blob | null;
    fileUrl: string | null;
}

const FileInfo: React.FC<FileInfoProps> = ({fileInfo, fileBlob, fileUrl}) => {
    if (!fileInfo || !fileBlob || !fileUrl) return null;

    const renderFilePreview = () => {
        if (fileInfo.contentType.startsWith('image/')) {
            return (
                <div className="mt-2">
                    <img
                        src={fileUrl}
                        alt="attachment"
                        className="max-w-full max-h-60 rounded-lg shadow-md cursor-pointer"
                        onClick={() => window.open(fileUrl, '_blank')}
                    />
                </div>
            );
        } else if (fileInfo.contentType === 'application/pdf') {
            return <FaFilePdf className="text-red-500 text-4xl cursor-pointer"
                              onClick={() => window.open(fileUrl, '_blank')}/>;
        } else if (fileInfo.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return <FaFileWord className="text-blue-500 text-4xl cursor-pointer"
                               onClick={() => window.open(fileUrl, '_blank')}/>;
        } else if (fileInfo.contentType === 'audio/ogg' || fileInfo.contentType === 'audio/oga') {
            return (
                <div className="w-56 bg-gray-100 rounded-xl shadow-md flex items-center space-x-3">
                    <audio controls className="w-full rounded-lg focus:outline-none">
                        <source src={fileUrl} type={fileInfo.contentType}/>
                        <source src={fileUrl.replace('.oga', '.mp3')} type="audio/mpeg"/>
                        {/* на случай, если доступен mp3 */}
                        Your browser does not support the audio element.
                    </audio>
                </div>


            );
        } else {
            return <FaFileAlt className="text-slate-500 text-4xl cursor-pointer"
                              onClick={() => window.open(fileUrl, '_blank')}/>;
        }
    };

    if (fileInfo?.size) {
        const fileSizeMB = (fileInfo.size / (1024 * 1024)).toFixed(2);
        return (
            <div className="flex items-center mt-2 space-x-2">
                <div>{renderFilePreview()}</div>
                <div className="text-sm">
                    <div className="text-xs text-slate-500 break-all">{fileInfo.name}</div>
                    <div className="text-xs text-slate-500">{fileSizeMB} MB</div>
                </div>
            </div>
        );
    }
    return null;
};

export default FileInfo;
