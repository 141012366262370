import React, {useEffect, useState} from 'react';
import {useHeader} from '../../context/HeaderContext';
import ContentWrapper from "../atom/ContentWrapper";
import {clientWsBillingPlan, clientWsInvoiceResource} from "../../api/client-ws";
import {CurrentPlanResponse, InvoiceResponse} from "../types/types";
import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import Button, {ButtonColor, ButtonSize} from "../atom/Button";

const Billing: React.FC = () => {
    const {setHeaderContent} = useHeader();
    const [billingPlans, setBillingPlans] = useState<any[]>([]);
    const [currentPlan, setCurrentPlan] = useState<CurrentPlanResponse>();

    useEffect(() => {
        setHeaderContent('Платежи');
        fetchBillingPlans();

        // Добавление CloudPayments виджета
        const script = document.createElement('script');
        script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [setHeaderContent]);

    const fetchBillingPlans = async () => {
        try {
            const availablePlansResponse = await clientWsBillingPlan.getAvailableBillingPlans();
            const currentPlanResponse = await clientWsBillingPlan.getCurrentBillingPlan();

            setBillingPlans(availablePlansResponse.data.content);
            setCurrentPlan(currentPlanResponse.data);
        } catch (error) {
            console.error('Error fetching billing plans:', error);
        }
    };

    const handlePlanChange = async (plan: any) => {
        try {
            if (plan.billingPlanType !== currentPlan?.billingPlanType) {
                // получаем настройки для платежа
                const {data: invoice}: {
                    data: InvoiceResponse
                } = await clientWsInvoiceResource.createInvoice(plan.billingPlanType);

                // Если план платный, открываем виджет CloudPayments
                if (plan.price > 0) {
                    const widget = new (window as any).cp.CloudPayments();

                    widget.pay('charge', { // charge или auth
                            publicId: invoice.publicId, // ID из CloudPayments
                            description: `Оплата тарифа ${plan.billingPlanType}`, // Описание платежа
                            amount: invoice.amount, // Сумма
                            currency: invoice.currency, // Валюта
                            invoiceId: invoice.invoiceId, // Номер заказа, необязательно
                            accountId: invoice.accountId, // Идентификатор пользователя, необязательно
                            email: invoice.email, // E-mail пользователя, необязательно
                        },
                        {
                            onSuccess: async function () { // Успешная оплата
                                await clientWsBillingPlan.upgradePlanNow(plan.billingPlanType);
                                fetchBillingPlans(); // Обновление плана
                            },
                            onFail: function (reason: any) { // Ошибка оплаты
                                console.error('Payment failed:', reason);
                            }
                        });
                } else {
                    // Если план бесплатный, просто изменяем тариф
                    await clientWsBillingPlan.upgradePlanNow(plan.billingPlanType);
                    fetchBillingPlans();
                }
            }
        } catch (error) {
            console.error('Error changing billing plan:', error);
        }
    };

    return (
        <ContentWrapper>
            <div className="flex flex-col items-center justify-center h-screen space-y-6">
                <h1 className="text-3xl font-bold mb-6">Доступные Тарифы</h1>
                {billingPlans.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full max-w-4xl">
                        {billingPlans.map((plan) => (
                            <div
                                key={plan.billingPlanType}
                                className={`border rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow 
                        ${currentPlan?.billingPlanType === plan.billingPlanType ? 'border-blue-500' : 'border-gray-300'}`}
                            >
                                <h2 className="text-xl font-semibold">
                                    {plan.billingPlanType}
                                </h2>
                                <p className="text-lg font-bold">
                                    {plan.price > 0 ? `${plan.price} ₽` : 'Бесплатно'}
                                </p>
                                {currentPlan?.billingPlanType === plan.billingPlanType && (
                                    <div className="mt-4">
                                <span className="text-blue-500 font-medium">
                                    Текущий план
                                </span>
                                        {currentPlan?.nextPayTimestamp && plan.billingPlanType !== 'BASE' && (
                                            <p className="mt-2 text-slate-500">
                                                Следующий платёж:{" "}
                                                <span className="font-semibold text-blue-600">
                                            {format(new Date(currentPlan.nextPayTimestamp), "dd MMMM yyyy", {locale: ru})}
                                        </span>
                                            </p>
                                        )}
                                    </div>
                                )}
                                {currentPlan?.billingPlanType !== plan.billingPlanType && plan.billingPlanType !== 'BASE' && (
                                    <Button
                                        title="Перейти на этот план"
                                        onClick={() => handlePlanChange(plan)}
                                        buttonColor={ButtonColor.BLUE}
                                        buttonSize={ButtonSize.FULL}
                                        customClass="mt-4 py-5"
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Загрузка тарифных планов...</p>
                )}
            </div>
        </ContentWrapper>
    );
};

export default Billing;
